import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'


const data = [
    {
        id: 1, icon: <RiReactjsLine/>, title: 'Frontend Development', desc: "Your product will look good and will be accessible on all devices, including mobile phones, tablets, and desktop."
    },
    {
        id: 2, icon: <FaServer/>, title: 'Backend Development', desc: "The security of your business/product is taken seriously right from the start of the project. I will make sure your website/app is secure from attacks."
    },

]


export default data